import React from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaFileAlt,FaCubes,FaThumbsUp,FaCheck,FaTools, FaQuoteLeft,FaArrowAltCircleRight} from 'react-icons/fa';
const Home = () => {
  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container'>
          <h2 className="home-h2">Background</h2>

                 <p className='home-p'>Founded and Incorporated in 2010, <strong>RAK ENGINEERING LTD</strong> has over 10 years of experience in Telecommunications, Civil and Electrical Engineering. We believe that the client comes to us so we can execute stunningly and beyond expectation.</p>
<p className='home-p'><strong>RAK ENGINEERING LTD</strong> has over 89 full-time and part-time employees covering Technical, Occupational Health & Safety (OHS) and Administration. We have pushed the grade and expertise too high hence becoming one of the leading Engineering companies in Uganda.</p>
<p className='home-p'>Our attitude and relentless nature make us execute projects with perfection thus registering commendable success. More so, high level finishing has kept us abreast with the industry best players.</p>

        </div>

    </section>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient( rgba(8, 8, 37, 0.85), rgba(0, 15, 80, 0.675)), url("./images/31.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Pledge</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>We are committed to providing our customers with services of high-quality that meet their needs beyond expectation. </p>
        </div>
    </section>

    <section className='home-service-cover'>
    {/* <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2> */}
     <h2 className='home-h2 home-service-h2'>What makes us unique?</h2>
   
    <div className='home-services-container '>
      {/* <div className='about-img-container'>
      <img src='./images/AWUKO1.jpg' alt='BENARD AWUKO' className='about-img'></img>
   </div> */}
       <div className='home-services-data'>
       <div className='home-services-info '>
       
        <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaFileAlt></FaFileAlt></span>
          </div>
          <h3 className='home-service-title'>Project Management</h3>
          <p>We are able to plan, schedule, execute, control and report on projects with minimal supervision. We also have the ability and willingness to handle all Right of Way matters on behalf of our clients.</p>

        </div>
        {/* <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaTools></FaTools></span>
          </div>
          <h3 className='home-service-title'>Construction & Installation</h3>
          <p>We provide upto six (6) employment teams, tools and transport to handle survey, Right-of-Way, material supply & installation, optical fiber termination & testing, commissioning, acceptance and handover.</p>
        </div> */}
<div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCheck></FaCheck></span>
          </div>
          <h3 className='home-service-title'>Safety</h3>
          <p>Our company and staff hold safety with utmost importance. As such, we are capable of conducting risk assessments and adhere to operating procedures and safety standards. We conduct periodic training and monitoring is done by qualified personnel. We also have key Personal Protective Equipment (PPE) and tools.</p>
        </div>

 <div className='home-service-box'>
           <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaCubes></FaCubes>  </span>
          </div>
          <h3 className='home-service-title'>Design</h3>
          <p>Based on customer requirements, we are able to design services and work closely with the team.</p>
        </div>


        <div className='home-service-box'>
          <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaThumbsUp></FaThumbsUp></span>
          </div>
          <h3 className='home-service-title'>Maintenance</h3>
          <p>We have standby teams dedicated to maintenance and support.  </p>
        </div>

        
    
    </div>
        <Link to="services" className='home-btn'>
         Our Services
         </Link>


        </div>


    </div>



  </section>
  <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To maintain a healthy and pollution free environment through more use of scientifically approved and friendly technologies. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To attract, develop and retain performance driven teams and individuals by providing an environment that fosters staff empowerment, fair competition and rewarding excellence.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To care for communities in which we work by inculcating and upholding high ethical standards throughout the service chain.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To ensure fair, equitable and respectful treatment of customers and suppliers in accordance with internally established procedures. </p>
                     </div>

                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>




       </div>

  </section>
    
  
    </>
  )
}

export default Home