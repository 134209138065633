import Carousel from 'react-bootstrap/Carousel';

function BuildingsCarousel() {
  return (
    <Carousel  indicators={false} className="goals-carousel buildings" >
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="First slide"
        />
        {/* <Carousel.Caption>
          <h5>First slide label</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="Second slide"
        />
        {/* <Carousel.Caption>
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption> */}
      </Carousel.Item>
     
    </Carousel>
  );
}

export default BuildingsCarousel;