import React, {useEffect} from 'react'

const Gallery = () => {
  useEffect(()=>{
document.title = 'Gallery | RAK ENGINEERING LTD';

},[]);
  return (
    <section className='section-cover gallery-cover' >
       <h2 className="home-h2 gallery-h2">Our Gallery</h2>
      <div className='content-container gallery'>
         <article className='gallery-content'>
          <img src='./images/33.JPG' className='gallery-image' alt='image'></img>
           <p>Compaction</p>
         </article>
         <article className='gallery-content'>
          <img src='./images/34.JPG' className='gallery-image' alt='image'></img>
           <p>Compactor</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/35.JPG' className='gallery-image' alt='image'></img>
           <p>Excavation for Manhole Construction</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/36.JPG' className='gallery-image' alt='image'></img>
           <p>Field Technician</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/37.JPG' className='gallery-image' alt='image'></img>
           <p>Field Vehicle</p>
         </article>

           <article className='gallery-content'>
          <img src='./images/38.JPG' className='gallery-image' alt='image'></img>
           <p>Fusion Splicer </p>
         </article>

          <article className='gallery-content'>
          <img src='./images/39.JPG' className='gallery-image' alt='image'></img>
           <p>Fusion Splicing </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/40.JPG' className='gallery-image' alt='image'></img>
           <p>Hand Trenching </p>
         </article>

         <article className='gallery-content'>
          <img src='./images/41.JPG' className='gallery-image' alt='image'></img>
           <p>Hand Trenching within vicinity of services</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/42.JPG' className='gallery-image' alt='image'></img>
           <p>Jumping Compaction after Hand Trenching</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/43.JPG' className='gallery-image' alt='image'></img>
           <p>Jumping Compactor</p>
         </article>

         
          <article className='gallery-content'>
          <img src='./images/44.JPG' className='gallery-image' alt='image'></img>
           <p>Manhole Construction</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/45.JPG' className='gallery-image' alt='image'></img>
           <p>Manhole Cover Placement</p>
         </article>

            <article className='gallery-content'>
          <img src='./images/46.jpeg' className='gallery-image' alt='image'></img>
           <p>Optical Time Domain Reflectometer (OTDR)</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/47.jpeg' className='gallery-image' alt='image'></img>
           <p>Splicer</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/48.JPG' className='gallery-image' alt='image'></img>
           <p>Splicer</p>
         </article>

         <article className='gallery-content'>
          <img src='./images/49.JPG' className='gallery-image' alt='image'></img>
           <p>Trenching</p>
         </article>

          <article className='gallery-content'>
          <img src='./images/50.JPG' className='gallery-image' alt='image'></img>
           <p>Warning Tape Laying</p>
         </article>




      </div>

    </section>
  )
}

export default Gallery