import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient( rgba(37, 85, 157, 0.852), rgba(0, 0, 0, 0.863)), url("./images/30.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

 <div className='footer-inner-content'>
                    {/* <div className='footer-location'>
                      <h3>We Are Located At</h3>
                      <p>Plot 2228, Kasangati - Matugga Road<br></br> P. O. Box 26940, Kampala - Uganda</p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>+256393 243285<br></br>+256772 280273</p>

                    </div> */}

                      <div className='footer-contacts'>
                      <h3>Our Vision</h3>
                      <p className='footer-vm'>To be the provider of superior customer service through application of technological innovations that optimize resource utilization and environmental conservation.</p>

                    </div>
                    <div className='footer-contacts'>
                      <h3>Our Mission</h3>
                      <p className='footer-vm'>To continuously offer relevant, safe, affordable and environmentally acceptable Engineering solutions that meet customer and societal needs by engaging in research and innovation.</p>

                    </div>

             </div>



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='who-we-are' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Who We Are</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='team' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Team</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Services</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='clients' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Clients</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='certification' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Certification</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='gallery' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Gallery</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='regulatory-compliance' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Regulatory Compliance</p></Link> 
                        </div>
                      
                      
                    </div>

             </div>

            

             <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='telecom' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Telecom & Networking Services</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='power' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Power Line Design & Construction</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='road-construction' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Road Construction & Maintenance</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='support' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Support & Managed Services</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='maintenance' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Preventive Maintenance</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='emergency-response' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Emergency Response</p></Link> 
                        </div>

                         <div className='footer-link'>
                            <Link to='building-construction' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Building Construction</p></Link> 
                        </div>
                      
                      
                    </div>

             </div>

             <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Location</h3>
                      <p>Plot 2228, Kasangati - Matugga Road<br></br> P. O. Box 26940, Kampala - Uganda</p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>+256393 243285<br></br>+256772 280273</p>

                    </div>

                     <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  <p>info@rakengineering-ug.com</p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span>
                                 <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>

                                 <span>
<FaLinkedin></FaLinkedin>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> RAK ENGINEERING LTD | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer