import React from 'react'

let ValuesData = [

{title: "Devotion to Excellence",      
content: "Through study and research, we daily strive to offer the best services to our customers. We believe that today is better than yesterday and tomorrow ought to be better than today."
},

{title: "Equity",      
content: "We preach and foster fairness for all our employees and stakeholders by providing fair and equitable renumeration and treating all stakeholders in a deserving manner."
},
{title: "Passion",      
content: "We are passionate about customers' needs and satisfaction. Hence, we build long-term customer relationships."
},
{title: "Professionalism",      
content: "We strive for excellence in all our endeavors."
},

{title: "Integrity ",      
content: "We serve our customers with diligence, transparency, accountability and utmost honesty."
},

{title: "Care",      
content: "We cherish and honour the contibution of every employee of the organization, knowing that good employees are the conerstone of our success."
},

{title: "Customer Satisfaction",      
content: "We value and put our customers' needs above everything else."
},

{title: "Teamwork",      
content: " We encourage and promote the spirit of teamwork, trust and partnerships. 'One for all, all for one."
},


];

export default ValuesData;