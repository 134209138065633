import Carousel from 'react-bootstrap/Carousel';

function PowerCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/6.jpeg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/17.jpeg"
          alt="Second slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/26.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpeg"
          alt="Third slide"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default PowerCarousel;