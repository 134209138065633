import Carousel from 'react-bootstrap/Carousel';

function ContactCarousel() {
  return (
    <Carousel indicators={false} className="contact-slides-cover">
      <Carousel.Item interval={3000} >
        <img
          className="d-block w-100"
          src="./images/8.JPG"
          alt="First slide"
        />
        
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/9.JPG"
          alt="Second slide"
        />
       
      </Carousel.Item>
      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/10.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/11.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/12.JPG"
          alt="Third slide"
        />   
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/13.JPG"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/6.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/1.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/14.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>

      <Carousel.Item interval={3000}>
        <img
          className="d-block w-100"
          src="./images/15.jpeg"
          alt="Third slide"
        />
       
      </Carousel.Item>





    </Carousel>
  );
}

export default ContactCarousel;