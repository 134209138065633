import Carousel from 'react-bootstrap/Carousel';

function SupportCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>
      
     
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/ict.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

    

    </Carousel>
  );
}

export default SupportCarousel;